import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      title:
        "Umohe Healthcare - This is medical practitioners offer their services",
      metaTags: [
        {
          name: "keywords",
          content:
            "mira, umohe, Healthcare, healthcare providers, umohe healthcare",
        },
        {
          name: "description",
          content: "Mira Healthcare providers. ",
        },
        {
          property: "og:description",
          content: "Doctors, nurses, psychiatrist, medical assistants",
        },
      ],
    },
  },
  {
    path: "/remember",
    name: "remember",
    component: () => import("../views/Remember.vue"),
    props: (route) => ({ query: route.query.q }),
    meta: {
      title:
        "Umohe Healthcare - This is medical practitioners offer their services",
      metaTags: [
        {
          name: "keywords",
          content:
            "mira, umohe, Healthcare, healthcare providers, umohe healthcare",
        },
        {
          name: "description",
          content: "Mira Healthcare providers. ",
        },
        {
          property: "og:description",
          content: "Doctors, nurses, psychiatrist, medical assistants",
        },
      ],
    },
  },
  {
    path: "/medic",
    name: "medic",
    // beforeEnter: (to, from, next) => {
    //   let e = localStorage.getItem('user.subscriber_type');
    //   console.log(e)
    //   if (e === 1) {
    //     // next();
    //     router.push({ name: 'medic.dash' })
    //       .catch(error => {
    //         console.info(error)
    //       })
    //   } else {
    //     next({ name: 'home' });
    //   }
    // },
    component: () => import("../views/Medics/MedicsView.vue"),
    meta: {
      title: "Umohe Healthcare - Virtual and personalized Healthcare Providers",
      metaTags: [
        {
          name: "keywords",
          content:
            "mira, umohe, Healthcare, healthcare providers, umohe healthcare",
        },
        {
          name: "description",
          content: "Mira Healthcare providers. ",
        },
        {
          property: "og:description",
          content: "Doctors, nurses, psychiatrist, medical assistants",
        },
      ],
    },
    children: [
      {
        path: "dash",
        name: "medics.dash",
        component: () => import("../views/Medics/dashboard.vue"),
      },
      {
        path: "services",
        name: "medics.services",
        component: () => import("../views/Medics/services.vue"),
      },
      {
        path: "services_more/:id/:name",
        props: true,
        name: "medics.services.add",
        component: () => import("../views/Medics/services_more.vue"),
      },
      {
        path: "account",
        name: "medics.account",
        component: () => import("../views/user/myaccount.vue"),
        redirect: { path: "account/personal" },
        children: [
          {
            path: "background",
            name: "account.background",
            component: () => import("../views/user/pages/background.vue"),
          },
          {
            path: "blog",
            name: "account.blog",
            component: () => import("../views/user/pages/blog.vue"),
            children: [
              {
                path: "new",
                name: "account.blog.new",
                component: () => import("../views/user/pages/blogNew.vue"),
              },
              {
                path: "page/:id/:title",
                props: true,
                name: "account.blog.page",
                component: () => import("../views/user/pages/blogPage.vue"),
              },
            ],
          },
          {
            path: "business",
            name: "account.business",
            component: () => import("../views/user/pages/business.vue"),
          },
          {
            path: "certs",
            name: "account.certs",
            component: () => import("../views/user/pages/certs.vue"),
          },
          {
            path: "personal",
            name: "account.personal",
            component: () => import("../views/user/pages/personal.vue"),
          },
          {
            path: "profile",
            name: "account.profile",
            component: () => import("../views/user/pages/profile.vue"),
          },
          {
            path: "subscriptions",
            name: "account.subscriptions",
            component: () => import("../views/user/pages/subscriptions.vue"),
          },
        ],
      },
      {
        path: "schedule",
        name: "medics.schedule",
        component: () => import("../views/Medics/schedules.vue"),
      },
      {
        path: "appoint",
        name: "medics.appointment",
        component: () => import("../views/Medics/appointment.vue"),
      },
      {
        path: "client",
        name: "medics.client",
        component: () => import("../views/Medics/PatientSearch.vue"),
      },
      {
        path: "appointment/:id/:date/:loc/:start/:end/:pat",
        name: "medics.appointment.more",
        props: true,
        component: () => import("../views/Medics/appoint_more.vue"),
      },
      {
        path: "treatment/:id/:name/:patid",
        name: "medics.treatment",
        props: true,
        component: () => import("../views/Medics/treatments.vue"),
      },
      {
        path: "histreat",
        name: "medics.histreat",
        component: () => import("../views/Medics/treatment_history.vue"),
      },
      {
        path: "reviews",
        name: "medics.reviews",
        component: () => import("../views/Medics/reviews.vue"),
      },
    ],
  },
  {
    path: "/patient",
    name: "patient",
    // beforeEnter: (to, from, next) => {
    //   if (localStorage.getItem('user.subscriber_name') === 'medics') {
    //     next();
    //   } else {
    //     next({ name: 'home' });
    //   }
    // },
    component: () => import("../views/Patients/PatientView.vue"),
    meta: {
      title: "Umohe Healthcare - Virtual and personalized Healthcare Providers",
      metaTags: [
        {
          name: "keywords",
          content:
            "mira, umohe, Healthcare, healthcare providers, umohe healthcare",
        },
        {
          name: "description",
          content: "Mira Healthcare providers. ",
        },
        {
          property: "og:description",
          content: "Doctors, nurses, psychiatrist, medical assistants",
        },
      ],
    },
    children: [
      {
        path: "dash",
        name: "patient.dash",
        component: () => import("../views/Patients/dashboard.vue"),
      },
      {
        path: "blogs/:id",
        props: true,
        name: "patient.blogs",
        component: () => import("../views/Patients/healthBlog.vue"),
      },
      {
        path: "dependents",
        name: "patient.dependents",
        component: () => import("../views/Patients/DependencyView.vue"),
      },
      {
        path: "account",
        name: "patient.account",
        component: () => import("../views/user/my_patient.vue"),
      },
      {
        path: "requests",
        name: "patient.request",
        component: () => import("../views/Patients/requests.vue"),
      },
      {
        path: "pending",
        name: "patient.pending",
        component: () => import("../views/Patients/pending.vue"),
      },
      {
        path: "requests/:id/:salute/:first/:last",
        name: "patient.request.date",
        props: true,
        component: () => import("../views/Patients/makerequest.vue"),
      },
      {
        path: "appointments",
        name: "patient.appointments",
        component: () => import("../views/Patients/appointments.vue"),
      },
      {
        path: "appointments/follow/:id/:subscriber/:date",
        name: "patient.appointments.followup",
        props: true,
        component: () => import("../views/Patients/appointments/followup.vue"),
      },
      // {
      //   path: "appointments/:id/:subscriber/:date",
      //   name: "patient.appointments.followup",
      //   props: true,
      //   component: () => import("../views/Patients/followup.vue"),
      // },
      {
        path: "more/:id/:date/:loc/:start/:end",
        name: "patient.appointments.more",
        props: true,
        component: () => import("../views/Patients/appoint_more.vue"),
      },
      {
        path: "evid/:id/:date/:loc/:start/:end",
        name: "patient.appointments.evidence",
        props: true,
        component: () => import("../views/Patients/evidense.vue"),
      },
      {
        path: "pharma",
        name: "patient.pharma",
        component: () => import("../views/Patients/pharmacy.vue"),
      },
      {
        path: "history",
        name: "patient.history",
        component: () => import("../views/Patients/history.vue"),
      },
      {
        path: "history/detail/:id/:subscriber/:date",
        name: "patient.history.detail",
        props: true,
        component: () => import("../views/Patients/history_detail.vue"),
      },
      {
        path: "reviews",
        name: "patient.reviews",
        component: () => import("../views/Patients/reviews.vue"),
      },
      {
        path: "payments",
        name: "patient.payments",
        component: () => import("../views/Patients/paymentMade.vue"),
      },
      {
        path: "checkout",
        name: "checkout",
        component: () => import("../views/checkout/checkout.vue"),
        children: [
          {
            path: "now/:id",
            name: "pay.now",
            props: true,
            component: () => import("../views/checkout/pay.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/lab",
    name: "lab",
    component: () => import("../views/Labs/LaboratoryView.vue"),
    children: [
      {
        path: "dash",
        name: "lab.dash",
        component: () => import("../views/Labs/dashboard.vue"),
      },
      {
        path: "account",
        name: "lab.account",
        component: () => import("../views/user/myaccount.vue"),
      },
      {
        path: "services",
        name: "lab.services",
        component: () => import("../views/Labs/services.vue"),
      },
      {
        path: "centers",
        name: "lab.centers",
        component: () => import("../views/Labs/centers.vue"),
      },
      {
        path: "center/:id/:name",
        name: "lab.centers.detail",
        props: true,
        component: () => import("../views/Labs/center_detail.vue"),
      },
      {
        path: "users",
        name: "lab.users",
        component: () => import("../views/user/subscriber_users.vue"),
      },
      {
        path: "request",
        name: "lab.request",
        component: () => import("../views/Labs/requests.vue"),
      },
      {
        path: "request/answer/:d/:req",
        name: "lab.request.accept",
        props: true,
        component: () => import("../views/Labs/answers.vue"),
      },
      {
        path: "purchases",
        name: "lab.purchases",
        component: () => import("../views/Labs/purchases.vue"),
      },
    ],
  },
  {
    path: "/pharma",
    name: "pharma",
    component: () => import("../views/Pharma/PharmacyView.vue"),
    children: [
      {
        path: "dash",
        name: "pharma.dash",
        component: () => import("../views/Pharma/dashboard.vue"),
      },
      {
        path: "account",
        name: "pharma.account",
        component: () => import("../views/user/myaccount.vue"),
      },
      {
        path: "stocks",
        name: "pharma.stocks",
        component: () => import("../views/Pharma/stocks.vue"),
      },
      {
        path: "shops",
        name: "pharma.shops",
        component: () => import("../views/Pharma/shops.vue"),
      },
      {
        path: "shop",
        name: "pharma.centers",
        component: () => import("../views/Pharma/centers.vue"),
      },
      {
        path: "shops/:id/:name",
        name: "pharma.centers.stocks",
        props: true,
        component: () => import("../views/Pharma/shop_stocks.vue"),
      },
      {
        path: "users",
        name: "pharma.users",
        component: () => import("../views/user/subscriber_users.vue"),
      },
      //
      {
        path: "request",
        name: "pharma.request",
        component: () => import("../views/Pharma/Requests.vue"),
      },
      {
        path: "purch",
        name: "pharma.purchased",
        component: () => import("../views/Pharma/Purchases.vue"),
      },
      {
        path: "reviews",
        name: "pharma.reviews",
        component: () => import("../views/Pharma/reviews.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (localStorage.getItem("user.token") === "") {
    console.log("nothing");
    next({ name: "home" });
  } else {
    // console.log("hee yes");
    next();
  }
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );
  if (!nearestWithMeta) return next();
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute("data-vue-router-controlled", "");
      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));
  next();
});

export default router;
