import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  prescribeState: [],
};

const getters = {
  getPresc: (state) => state.prescribeState,
};

const mutations = {
  addPresc: (state, pres) => state.prescribeState.unshift(pres),
  updatePres: (state, pres) => (
    console.log("state", state),
    state.prescribeState.find((o) => (o.id = pres.id)),
    state.prescribeState.filter((p) => p.id !== pres.id),
    state.prescribeState.splice((p) => p.id, 1),
    state.prescribeState.unshift(pres)
  ),
  removePres: (state, id) => (
    state.prescribeState.filter((p) => p.id !== id),
    state.prescribeState.splice((p) => p.id, 1)
  ),
  removeAll: (state) => {
    state.prescribeState = [];
  },
};

const actions = {
  create: (context, payload) => {
    context.commit("addPresc", payload);
  },
  editPres: (context, payload) => {
    context.commit("updatePres", payload);
  },
  remove: (context, payload) => {
    context.commit("removePres", payload);
  },
  removeAll: (context) => {
    context.commit("removeAll");
  },
};

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
});
