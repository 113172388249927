import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import moment from "moment";
import Vuelidate from "vuelidate";
import * as VueGoogleMaps from "vue2-google-maps";
import store from "./store";
// styles
require("./assets/Fonts/roboto-cufonfonts-webfont/style.css");
require("./assets/Fonts/helvetica-neue-lt-std-55-cufonfonts-webfont/style.css");
require("./assets/Fonts/merriweather-cufonfonts-webfont/style.css");
require("./assets/css/vuetify.css");
require("./assets/css/styles.css");
// usages
// Vue.use(DatetimePicker);
Vue.config.productionTip = false;
Vue.use(Vuelidate);
// Vue.use(ImageUploader);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCxQaVG3M47ce_0LudBg3tPszNA2ztTMlE",
    libraries: "places",
  },
});
// filters
Vue.filter("myDating", function (value) {
  if (value) {
    return moment(value).format("Do MMM, YYYY hh:mm ss a");
  }
});
Vue.filter("simpleDating", function (value) {
  if (value) {
    let date = new Date(value);
    return moment(date).format("Do MMM, YYYY hh:mm a");
  }
});
Vue.filter("simpleDate", function (value) {
  if (value) {
    let date = new Date(value);
    return moment(date).format("Do MMM hh:mm a");
  }
});
Vue.filter("custDate", function (value) {
  if (value) {
    let date = new Date(value);
    return moment(date).format("Do MMM, YYYY");
  }
});
Vue.filter("myDate", function (value) {
  if (value) {
    return moment(value).format("Do MMM, YYYY");
  }
});
Vue.filter("myTime", function (value) {
  if (value) {
    return moment(value, "HH:mm").format("h:mm:ss A");
  }
});
Vue.filter("myDateTiming", function (value) {
  if (value) {
    let d = new Date(value);
    let begin = moment(d).format("hh:mm a");
    return begin;
  }
});

Vue.filter("capitalize", function (value) {
  if (value) {
    let val = value.toString().trim();
    let arr = val.split(" ");
    let upper = arr.map((element) => {
      return element.charAt(0).toUpperCase() + element.slice(1);
    });
    let arrString = upper.join(" ");
    return arrString;
  }
});

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
